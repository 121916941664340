import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LeadGenerationForm from './components/LeadGenerationForm';
import Layout from './components/Layout';
import { HelmetProvider } from "react-helmet-async";
import Home from './pages/Home';
import Contact from './pages/Contact';
import EmergencyTowingPhoenix from './pages/EmergencyTowingPhoenix';
import TowingServiceScottsdale from './pages/TowingServiceScottsdale';
import RoadsideAssistancePhoenix from './pages/RoadsideAssistancePhoenix';
import PhoenixAutoAccidentTowing from "./pages/PhoenixAutoAccidentTowing";
import FlatTireAssistancePhoenix from './pages/FlatTireAssistancePhoenix';
import FlatbedTowingPhoenix from './pages/FlatbedTowingPhoenix';
import MotorcycleTowingPhoenix from './pages/MotorcycleTowingPhoenix';
import TowingServiceGlendale from './pages/TowingServiceGlendale';
import TowingServiceMesa from './pages/TowingServiceMesa';
import TowingServiceTempe from './pages/TowingServiceTempe';
import TowingServicePhoenix from './pages/TowingServicePhoenix';
import './index.css';

// 404 Not Found Component
const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

// Function to handle "Share Location"
function shareLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        alert(`Location shared: Latitude ${latitude}, Longitude ${longitude}`);
        // Add your logic here to send the coordinates to the server or display them
      },
      (error) => {
        console.error("Error sharing location:", error);
        alert("Unable to retrieve location. Please enable location services.");
      }
    );
  } else {
    alert("Geolocation is not supported by your browser.");
  }
}

// Function to handle "Call Now"
function callNow() {
  const phoneNumber = "+1234567890"; // Update with your actual phone number
  window.location.href = `tel:${phoneNumber}`;
}

// Attach functions to the global window object
window.shareLocation = shareLocation;
window.callNow = callNow;

// Check for lead-gen-form container in index.html
const leadGenContainer = document.getElementById('lead-gen-form');
if (leadGenContainer) {
  const leadGenRoot = createRoot(leadGenContainer);
  leadGenRoot.render(
    <React.StrictMode>
      <LeadGenerationForm />
    </React.StrictMode>
  );
}

// Main app rendering
const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/emergency-towing-phoenix"
              element={
                  <EmergencyTowingPhoenix />
              }
            />
            <Route
              path="/towing-service-scottsdale"
              element={
                  <TowingServiceScottsdale />
              }
            />
            <Route path="/roadside-assistance-phoenix"
              element={<RoadsideAssistancePhoenix />}
            />
            <Route path="/phoenix-auto-accident-towing" element={<PhoenixAutoAccidentTowing />}
            />
            <Route path="/flat-tire-assistance-phoenix" element={<FlatTireAssistancePhoenix />}
            />
            <Route path="/flatbed-towing-phoenix" element={<FlatbedTowingPhoenix />} 
            />
            <Route path="/motorcycle-towing-phoenix" element={<MotorcycleTowingPhoenix />} 
            />
            <Route path="/towing-service-glendale" element={<TowingServiceGlendale />} 
            />
            <Route path="/towing-service-mesa" element={<TowingServiceMesa />} 
            />
            <Route path="/towing-service-tempe" element={<TowingServiceTempe />} 
            />
            <Route path="/towing-service-phoenix" element={<TowingServicePhoenix />} 
            />
            <Route path="/contact" element={<Contact />} 
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </React.StrictMode>
  );
}