import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const RoadsideAssistancePhoenix = () => {
    // Breadcrumbs specific to this page
    const breadcrumbs = [
      { label: "Home", href: "/" },
      { label: "Roadside Assistance Phoenix", href: "/roadside-assistance-phoenix", active: true },
    ];
  
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>Quick Phoenix Roadside Assistance - 24/7 Service Available</title>
        <meta
          name="description"
          content="Stuck in Phoenix? Access 24/7 roadside assistance for any vehicle. Quick, professional service—from towing to tire changes. Call now for immediate help!"
        />
      </Helmet>

      <div className="page-content container">
        {/* Hero Section */}
        <header className="hero-section text-center py-4 bg-light">
          <h1 className="fw-bold mb-3">
            Quick Phoenix Roadside Assistance -<br />
            <span>24/7 Service Available</span>
          </h1>
          <p className="mb-4">
            Stuck in Phoenix? Access professional roadside assistance anytime, anywhere.
          </p>
          <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mx-2"
        >
          Call Now: 602-483-1751
        </button>
        <button
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
          className="btn btn-primary btn-lg"
        >
          Request Service
        </button>
        </header>

        {/* Page Content */}
        <section className="intro">
          <h2 className="mb-3">Welcome to Quick Phoenix Roadside Assistance</h2>
          <p>
            Your lifeline on the roads of Phoenix, offering quick and reliable roadside solutions
            24/7. Emergencies don’t wait, and neither do we. Count on us to be there when you need us the most.
          </p>
        </section>

        <section className="services">
          <h2 className="mb-3">Comprehensive Services Tailored to Your Needs</h2>
          <p>From towing to immediate roadside recovery, we offer a full range of services:</p>
          <ul>
            <li>
              <strong>Towing and Recovery:</strong> Immediate support for all vehicle types.
            </li>
            <li>
              <strong>Vehicle Lockout Solutions:</strong> Regain access to your car without damage.
            </li>
            <li>
              <strong>Battery Jumpstarts:</strong> Dead battery? We’ll bring it back to life.
            </li>
            <li>
              <strong>Flat Tire Changes:</strong> Quick and efficient tire replacements.
            </li>
            <li>
              <strong>Gas Delivery:</strong> Running out of fuel? We’ve got you covered.
            </li>
          </ul>
        </section>

        <section className="coverage">
          <h2 className="mb-3">Areas We Cover</h2>
          <p>
            Our services extend citywide across the Phoenix metro area, ensuring help is never far
            away. For long-distance needs, we offer extended service coverage to get you back on
            track.
          </p>
        </section>

        <section className="why-choose-us">
          <h2 className="mb-3">Why Choose Us?</h2>
          <p>
            Our experienced team sets the industry standard for roadside assistance with fast
            response times, state-of-the-art equipment, and a commitment to your safety and
            satisfaction.
          </p>
          <ul>
            <li><strong>Expert Team:</strong> Professional and courteous roadside assistance specialists.</li>
            <li><strong>Fast Response:</strong> We pride ourselves on industry-leading response times.</li>
            <li><strong>Reliability:</strong> Safety and efficiency are our top priorities.</li>
            <li><strong>Advanced Equipment:</strong> Tools and vehicles designed for quick and effective service.</li>
          </ul>
        </section>

        <section className="testimonials">
          <h2 className="mb-3">Hear What Phoenix Drivers Say About Us</h2>
          <blockquote>
            <p>
              “Quick and professional service when I needed it most. They got me back on the road in no time!” – Sarah M.
            </p>
          </blockquote>
          <blockquote>
            <p>
              “Best roadside assistance in Phoenix! Reliable, affordable, and always on time.” – Mark D.
            </p>
          </blockquote>
        </section>

        <section className="faq">
          <h2 className="mb-3">FAQs: Phoenix, Arizona Roadside Assistance</h2>
          <h3>How can I request roadside assistance, and what information will I need to provide?</h3>
          <p>
            Simply call us at <strong>(602) 483-1751</strong>. Be ready to provide your location, vehicle type, and the type of assistance you need.
          </p>
          <h3>What types of roadside assistance services are available in Phoenix, AZ?</h3>
          <p>
            We offer towing, lockout services, battery jumpstarts, tire changes, gas delivery, and more.
          </p>
          <h3>How quickly can I expect help to arrive after I call for roadside assistance in Phoenix?</h3>
          <p>
            Our team typically arrives within 20-30 minutes, depending on traffic and location.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Contact Us</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default RoadsideAssistancePhoenix;