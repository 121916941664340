import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const TowingServiceScottsdale = () => {
  const breadcrumbs = [
    { label: "Home", href: "/" },
    { label: "Scottsdale Towing", href: "/towing-service-scottsdale", active: true },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>Scottsdale's Best Towing Company - 24/7 Towing in Scottsdale Arizona</title>
        <meta
          name="description"
          content="Stranded in Scottsdale? You can rely on Scottsdale's finest towing service to get you back on track. With 24/7 availability and a team of skilled professionals, we’re ready to assist you anytime, anywhere."
        />
      </Helmet>

      {/* Hero Section */}
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          Scottsdale's Best Towing Company:<br />
          <span>24/7 Towing in Scottsdale Arizona</span>
        </h1>
        <p className="mb-4">
          Stranded in Scottsdale? You can rely on Scottsdale's finest towing service to get you back on track.
        </p>
        <p className="mb-4">
          With 24/7 availability and a team of skilled professionals, we’re ready to assist you anytime, anywhere.
        </p>
        <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mx-2"
        >
          Call Now: 602-483-1751
        </button>
        <button
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
          className="btn btn-primary btn-lg"
        >
          Request Service
        </button>
      </header>

      {/* Page Content */}
      <div className="page-content container">
        <section className="intro">
          <h2 className="mb-3">Welcome to Scottsdale's Finest Towing Service</h2>
          <p>
            As your go-to experts for 24/7 towing in Arizona, we pride ourselves on providing swift and dependable
            solutions. Whether you’re facing a flat tire or need heavy-duty towing, we’ve got you covered.
          </p>
        </section>

        <section className="services">
          <h3 className="mb-3">Towing Company Scottsdale, Arizona</h3>
          <p>
            Always ready for any job, big or small, our team specializes in a wide range of services tailored to meet
            your specific needs. We handle light and heavy-duty towing, roadside assistance, and specialized services
            with the utmost care and efficiency.
          </p>
        </section>

        <section className="specialized-services">
          <h2 className="mb-3">Specialized Services for Unique Needs</h2>
          <h3 className="mb-2">Roadside Assistance at Its Finest</h3>
          <ul>
            <li>
              <strong>Roadside Help:</strong> Get quick solutions for emergencies like flat tires or dead batteries.
            </li>
            <li>
              <strong>Vehicle Lockout Service:</strong> Locked out of your car? Our experts will get you back in safely.
            </li>
            <li>
              <strong>Battery Jumpstarts:</strong> Dead battery? We’ll give you the jumpstart you need to keep moving.
            </li>
            <li>
              <strong>Flat Tire Changes:</strong> We’ll replace your tire and ensure you’re safely back on the road.
            </li>
            <li>
              <strong>Windshield Chip Repairs:</strong> Minor chips? We’ll fix them before they become a bigger problem.
            </li>
            <li>
              <strong>DUI Prevention Assistance:</strong> Avoid unnecessary risks; we’ll provide a safe tow to get you
              home.
            </li>
          </ul>
        </section>

        <section className="towing-services">
          <h3 className="mb-3">Light and Heavy-Duty Towing</h3>
          <p>
            From compact cars to large trucks, we have the equipment and expertise to tow vehicles of all sizes. Our
            specialized heavy-duty towing ensures even the toughest jobs are handled with precision.
          </p>
        </section>

        <section className="why-choose-us">
          <h2 className="mb-3">Why Choose Us?</h2>
          <p>
            Our experienced and efficient operators are trusted by Scottsdale residents for reliable and affordable
            towing services. Partnering with local businesses and auto body shops, we are proud to be a community-focused
            company that supports your journey.
          </p>
        </section>

        <section className="supporting-your-journey">
          <h3 className="mb-3">Supporting Your Journey</h3>
          <ul>
            <li>
              <strong>Need a Jumpstart?</strong> Call us day or night for immediate assistance.
            </li>
            <li>
              <strong>Fuel Delivery:</strong> Running on empty? We’ll bring fuel straight to you.
            </li>
          </ul>
          <p>
            Recommended by top auto body shops in Scottsdale, we’re the towing service you can trust for all your needs.
          </p>
        </section>

        <section className="quick-contact">
          <h2 className="mb-3">Quick Contact and Rapid Response</h2>
          <p>
            Have an emergency? Contact us anytime at <strong>(602) 483-1751</strong>. Our commitment to promptness ensures
            that help is always just a phone call away.
          </p>
        </section>

        <section className="faq">
          <h2 className="mb-3">FAQ: Your Questions, Answered</h2>
          <h3>What areas do you serve within and around Scottsdale?</h3>
          <p>
            We proudly serve all neighborhoods in Scottsdale and surrounding areas, offering fast and reliable towing
            services wherever you need us.
          </p>
          <h3>How can I get a quote for my towing needs?</h3>
          <p>
            Call us at <strong>(602) 483-1751</strong> or use our online form to get a transparent and upfront service
            quote tailored to your situation.
          </p>
          <h3>Are your services available around the clock?</h3>
          <p>
            Yes! Our 24/7 service means we’re always here to help, no matter the time or day.
          </p>
          <h3>What types of vehicles can you tow?</h3>
          <p>
            We tow all types of vehicles, including motorcycles, cars, trucks, and even exotic or non-running vehicles.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Contact Us</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TowingServiceScottsdale;