import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const TowingServiceMesa = () => {
  const breadcrumbs = [
    { label: "Home", href: "/" },
    { label: "Mesa Towing", href: "/towing-service-mesa", active: true },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>Mesa's Trusted Towing Service - 24/7 Towing in Mesa AZ</title>
        <meta
          name="description"
          content="Need towing in Mesa, AZ? Count on us for fast, reliable service 24/7. Light and heavy-duty towing with skilled professionals ready to assist."
        />
      </Helmet>

      {/* Hero Section */}
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          Mesa's Trusted Towing Service:<br />
          <span>24/7 Towing in Mesa AZ</span>
        </h1>
        <p className="mb-4">
          Need towing in Mesa? Count on us for fast, reliable service anytime you need help.
        </p>
        <p className="mb-4">
          With 24/7 availability and a commitment to professionalism, we’re here to assist you around the clock.
        </p>
        <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mx-2"
        >
          Call Now: 602-483-1751
        </button>
        <button
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
          className="btn btn-primary btn-lg"
        >
          Request Service
        </button>
      </header>

      {/* Page Content */}
      <div className="page-content container">
        <section className="intro">
          <h2 className="mb-3">Welcome to Mesa's Most Reliable Towing Service</h2>
          <p>
            As Mesa’s trusted towing company, we provide prompt, dependable services tailored to your specific needs.
            From flat tires to heavy-duty towing, we’ve got you covered 24/7.
          </p>
        </section>

        <section className="services">
          <h3 className="mb-3">Comprehensive Towing Services in Mesa, AZ</h3>
          <p>
            Our team is equipped to handle a wide range of towing and roadside assistance needs. We pride ourselves on
            offering swift, efficient, and safe solutions to keep you moving.
          </p>
        </section>

        <section className="specialized-services">
          <h2 className="mb-3">Specialized Services for All Emergencies</h2>
          <h3 className="mb-2">Roadside Assistance with a Difference</h3>
          <ul>
            <li>
              <strong>Roadside Help:</strong> Immediate support for flat tires, dead batteries, and other roadside emergencies.
            </li>
            <li>
              <strong>Vehicle Lockout Service:</strong> Locked out of your car? Let our experts get you back in quickly and safely.
            </li>
            <li>
              <strong>Battery Jumpstarts:</strong> Dead battery? We’ll jumpstart your vehicle and get you back on your way.
            </li>
            <li>
              <strong>Flat Tire Changes:</strong> Quick tire replacement to ensure your safety on the road.
            </li>
            <li>
              <strong>Windshield Chip Repairs:</strong> Repair chips before they spread and cause more damage.
            </li>
            <li>
              <strong>Heat-Related Breakdown Assistance:</strong> Reliable support to help you during extreme temperatures.
            </li>
            <li>
              <strong>Monsoon Season Emergency Response:</strong> Rapid assistance for weather-related issues.
            </li>
          </ul>
        </section>

        <section className="key-areas">
          <h3 className="mb-3">Key Areas We Serve in Mesa</h3>
          <p>
            Our towing services cover the entire Mesa region, including the following major areas:
          </p>
          <ul>
            <li>North Central Mesa</li>
            <li>Downtown Mesa</li>
            <li>Dobson Ranch</li>
            <li>Falcon Field Area</li>
            <li>Riverview</li>
            <li>Southeast Mesa</li>
            <li>Fiesta District</li>
            <li>US 60 Corridor</li>
          </ul>
        </section>

        <section className="towing-services">
          <h3 className="mb-3">Light and Heavy-Duty Towing Expertise</h3>
          <p>
            From small cars to oversized trucks, we have the equipment and knowledge to safely tow any vehicle. Trust
            our team to handle even the most challenging towing situations with ease.
          </p>
        </section>

        <section className="why-choose-us">
          <h2 className="mb-3">Why Choose Our Towing Service?</h2>
          <p>
            With years of experience serving Mesa residents, we are known for our professionalism, prompt service,
            and commitment to customer satisfaction. Our team works tirelessly to ensure you get the help you need, when
            you need it most.
          </p>
        </section>

        <section className="supporting-your-journey">
          <h3 className="mb-3">Here to Support You Anytime</h3>
          <ul>
            <li>
              <strong>Fuel Delivery:</strong> Run out of gas? We’ll bring fuel to your location so you can continue your journey.
            </li>
            <li>
              <strong>Safe Transportation:</strong> Need your car towed to a repair shop? We ensure secure and damage-free transport.
            </li>
          </ul>
        </section>

        <section className="quick-contact">
          <h2 className="mb-3">Get in Touch for Rapid Assistance</h2>
          <p>
            Have an emergency? Contact us at <strong>(602) 483-1751</strong> or fill out our contact form for prompt and reliable service.
          </p>
        </section>

        <section className="faq">
          <h2 className="mb-3">FAQs About Our Mesa Towing Services</h2>
          <h3>What areas in Mesa do you serve?</h3>
          <p>
            We serve all neighborhoods within Mesa, as well as nearby areas, providing prompt and reliable service wherever you need us.
          </p>
          <h3>Can I schedule a towing service in advance?</h3>
          <p>
            Yes, we offer both scheduled towing services and 24/7 emergency towing for your convenience.
          </p>
          <h3>What types of vehicles can you tow?</h3>
          <p>
            We can tow all types of vehicles, from motorcycles to heavy-duty trucks and even exotic or non-running vehicles.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Contact Us</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TowingServiceMesa;