import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const EmergencyTowingPhoenix = () => {
  // Breadcrumbs specific to this page
  const breadcrumbs = [
    { label: "Home", href: "/" },
    { label: "24/7 Emergency Towing Phoenix", href: "/emergency-towing-phoenix", active: true },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>Emergency Towing Phoenix - 24/7 Valley Wide</title>
        <meta
          name="description"
          content="Stranded in Phoenix? Our emergency towing is ready 24/7 valley-wide, offering dependable roadside assistance and quick response. Call (602) 483-1751 now!"
        />
      </Helmet>

    {/* Page-Specific Content */}
    <div className="page-content container">
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          24/7 Emergency Towing Phoenix - Immediate Response When You Need It Most
        </h1>
        <p className="lead mb-4">
          Fast 30-minute response time, certified operators, and full metro coverage. When you need emergency towing in Phoenix, we're here to help.
        </p>
        <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mx-2"
        >
          Call Now: (602) 483-1751
        </button>
        <button
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
          className="btn btn-primary btn-lg"
        >
          Request Emergency Service
        </button>
      </header>

      <section className="emergency-services my-5">
        <h2 className="mb-4">Emergency Services We Provide</h2>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-unstyled">
              <li className="mb-3">✓ Accident Recovery & Vehicle Extraction</li>
              <li className="mb-3">✓ Highway & Freeway Breakdowns</li>
              <li className="mb-3">✓ Dead Battery & Mechanical Failures</li>
              <li className="mb-3">✓ Flat Tire Emergencies</li>
              <li className="mb-3">✓ Off-Road Recovery</li>
              <li className="mb-3">✓ Stranded Vehicle Assistance</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="why-choose-us bg-light p-4 rounded my-5">
        <h2 className="mb-4">Why Choose Our Emergency Towing</h2>
        
        <div className="mb-4">
          <h3 className="h4">Lightning-Fast Response</h3>
          <ul>
            <li>30-minute average response time</li>
            <li>Available 24 hours, 7 days a week, 365 days a year</li>
            <li>GPS-tracked fleet for fastest dispatch</li>
            <li>Full Phoenix metro area coverage</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="h4">Professional Emergency Team</h3>
          <ul>
            <li>Licensed and certified operators</li>
            <li>Modern, well-maintained equipment</li>
            <li>Safe and damage-free towing</li>
            <li>Fully insured service</li>
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="h4">Comprehensive Emergency Support</h3>
          <ul>
            <li>All vehicle types handled</li>
            <li>Roadside assistance available</li>
            <li>Upfront pricing and no hidden fees</li>          </ul>
        </div>
      </section>

      <section className="emergency-situations my-5">
        <h2 className="mb-4">Emergency Situations We Handle</h2>
        <p>We respond to all emergency towing situations across Phoenix:</p>
        <ul>
          <li>Highway breakdowns (I-10, I-17, Loop 101, Loop 202)</li>
          <li>Accident recovery and vehicle extraction</li>
          <li>Engine failures in extreme heat</li>
          <li>Monsoon season emergencies</li>
          <li>Break-downs in dangerous areas</li>
          <li>Late-night vehicle problems</li>
          <li>Shopping center and parking lot incidents</li>
          <li>Residential area emergencies</li>
        </ul>
      </section>

      <section className="what-to-expect bg-light p-4 rounded my-5">
        <h2 className="mb-4">What to Expect When You Call (602) 483-1751</h2>
        <ol>
          <li>Immediate Response by Phone or Text Message</li>
          <li>Location Confirmation: We'll confirm your exact location</li>
          <li>Truck Dispatch: Nearest truck is immediately sent</li>
          <li>Professional Service: Safe and efficient vehicle recovery</li>
          <li>Flexible Payment: Multiple payment options accepted</li>
        </ol>
      </section>

      <section className="emergency-tips my-5">
        <h2 className="mb-4">Emergency Preparation Tips</h2>
        <ul>
          <li>Save our emergency number: (602) 483-1751</li>
          <li>Keep basic vehicle documentation handy</li>
          <li>Note your exact location when possible</li>
          <li>Stay safe inside your vehicle</li>
          <li>Turn on hazard lights</li>
          <li>Move to a safe location if possible</li>
        </ul>
      </section>

      <section className="service-areas my-5">
        <h2 className="mb-4">Service Areas</h2>
        <p>We provide rapid emergency towing throughout:</p>
        <ul>
          <li>Downtown Phoenix</li>
          <li>Sky Harbor Airport area</li>
          <li>Ahwatukee</li>
          <li>Deer Valley</li>
          <li>Desert Ridge</li>
          <li>Glendalte, Arizona</li>
          <li>Chandler, Arizona</li>
          <li>Gilbert, Arizona</li>
          <li>Mesa, Arizona</li>
          <li>Tempe, Arizona</li>
          <li>All surrounding communities</li>
        </ul>
      </section>

      <section className="faq bg-light p-4 rounded my-5">
        <h2 className="mb-4">Frequently Asked Questions</h2>
        <div className="mb-4">
          <h3 className="h5">How quickly can you reach me?</h3>
          <p>Our typical response time is 30-minute for most Phoenix metro locations. We will dispatch the nearest truck to your location.</p>
        </div>

        <div className="mb-4">
          <h3 className="h5">Do you handle all types of vehicles?</h3>
          <p>Yes, we have equipment to handle all vehicle types including cars, trucks, motorcycles, and commercial vehicles.</p>
        </div>

        <div className="mb-4">
          <h3 className="h5">Do you work with insurance companies?</h3>
          <p>Yes, we work with all major insurance providers and can handle direct billing in many cases.</p>
        </div>

        <div className="mb-4">
          <h3 className="h5">What information do I need to provide?</h3>
          <p>Your location, vehicle make/model, and nature of the emergency. We'll guide you through any additional information needed.</p>
        </div>
      </section>

      <section className="available-247 text-center my-5">
        <h2 className="mb-4">Available 24/7 for All Emergency Towing Needs</h2>
        <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mb-4"
        >
          Call Now: (602) 483-1751
        </button>
        <ul className="list-unstyled">
          <li>✓ Licensed and Insured Service</li>
          <li>✓ Professional, Certified Operators</li>
          <li>✓ All Major Payment Methods Accepted</li>
          <li>✓ 30-Minute Response Time On Average</li>
          <li>✓ Full Phoenix Metro Area Coverage</li>
        </ul>
        <p className="mt-4 font-italic">
          In case of emergency, stay safe and call us immediately at (602) 483-1751. Our emergency response team is standing by 24/7 to assist you.
        </p>
      </section>

      <section id="contact" className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center fw-bold mb-5">Request Emergency Service</h2>
          <div id="lead-gen-form">
            <LeadGenerationForm />
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
};

export default EmergencyTowingPhoenix;