import React from "react";
import { Helmet } from "react-helmet";
import LeadGenerationForm from "../components/LeadGenerationForm";
import Layout from "../components/Layout";

const MotorcycleTowingPhoenix = () => {
    // Breadcrumbs specific to this page
    const breadcrumbs = [
      { label: "Home", href: "/" },
      { label: "Motorcycle Towing Phoenix", href: "/motorcycle-towing-phoenix", active: true },
    ];
  
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className="motorcycle-towing-phoenix">
        <Helmet>
          <title>Arizona Motorcycle Towing Phoenix - Fast and Safe</title>
          <meta
            name="description"
            content="Need a reliable motorcycle tow in Phoenix? Get fast, safe service with transparent rates. 24/7 assistance for peace of mind on the road."
          />
        </Helmet>

        {/* Hero Section */}
        <header className="hero-section text-center py-4 bg-light">
          <h1 className="fw-bold mb-3">
            Arizona Motorcycle Towing Phoenix -<br />
            <span>Fast and Safe</span>
          </h1>
          <p className="mb-4">
            Need a reliable motorcycle tow? We offer fast, safe service with transparent rates. Available 24/7 for peace of mind on the road.
          </p>
          <button
            onClick={() => window.callNow()}
            className="btn btn-danger btn-lg fw-bold mx-2"
          >
            Call Now: 602-483-1751
          </button>
          <button
            onClick={() =>
              document.getElementById("contact").scrollIntoView({
                behavior: "smooth",
              })
            }
            className="btn btn-primary btn-lg"
          >
            Request Service
          </button>
        </header>

        {/* Page Content */}
        <div className="page-content container">
          <section className="intro">
            <h2 className="mb-3">Phoenix Motorcycle Towing Service</h2>
            <p>
              We understand how important your motorcycle is to you. Our team provides expert towing services to ensure your bike is transported securely and efficiently. Whether you’re dealing with a breakdown or need a scheduled tow, we’ve got you covered.
            </p>
          </section>

          <section className="services">
            <h2 className="mb-3">Services That Keep You Cruising</h2>
            <p>
              We’re here to support bikers with a range of services designed for your motorcycle's safety and your convenience:
            </p>
            <ul>
              <li><strong>24-Hour Roadside Assistance:</strong> Available anytime, anywhere in Phoenix and surrounding areas.</li>
              <li><strong>Safe and Secure Transport:</strong> Specialized equipment to protect your bike during transit.</li>
              <li><strong>Breakdown Recovery:</strong> Quick and efficient towing for breakdowns or accidents.</li>
            </ul>
          </section>

          <section className="why-choose-us">
            <h2 className="mb-3">Why Choose Us?</h2>
            <p>
              With years of experience and a commitment to bikers' safety, we’ve become a trusted name in Phoenix for motorcycle towing:
            </p>
            <ul>
              <li><strong>Specialized Expertise:</strong> Our team understands the unique needs of motorcycles and uses tailored solutions for every tow.</li>
              <li><strong>Transparent Pricing:</strong> No hidden fees or surprises—just honest rates for top-tier service.</li>
              <li><strong>24/7 Availability:</strong> We’re here whenever you need us, day or night.</li>
            </ul>
          </section>

          <section className="pricing">
            <h2 className="mb-3">Service Areas and Pricing</h2>
            <p>
              Our services cover the entire Phoenix metro area and surrounding counties. With upfront quotes and competitive pricing, you can trust us to provide exceptional value.
            </p>
            <ul>
              <li>Phoenix and nearby cities</li>
              <li>Maricopa County and beyond</li>
              <li>Local and long-distance towing</li>
            </ul>
            <p>
              <strong>No hidden fees:</strong> Get clear, honest pricing for every service.
            </p>
          </section>

          <section className="contact">
            <h2 className="mb-3">Getting In Touch Is Easy</h2>
            <p>
              Ready to get back on the road? Call us now for immediate assistance or schedule a tow at your convenience.
            </p>
            <button
              onClick={() => window.callNow()}
              className="btn btn-danger btn-lg fw-bold"
            >
              Call Now: 602-483-1751
            </button>
          </section>

          <section className="faq">
            <h2 className="mb-3">FAQs</h2>
            <h3>What is your coverage area for motorcycle towing?</h3>
            <p>
              We cover the entire Phoenix metro area, including Maricopa County and nearby regions. Long-distance towing is also available upon request.
            </p>
            <h3>Can I schedule a tow in advance, or do you offer only immediate service?</h3>
            <p>
              Yes, you can schedule a tow at your convenience or call us for immediate assistance 24/7.
            </p>
            <h3>How can I trust that my motorcycle will be safely transported?</h3>
            <p>
              Our team uses specialized equipment and techniques to ensure your motorcycle is securely handled and transported without damage.
            </p>
          </section>

          {/* Contact Form */}
          <section id="contact" className="py-5 bg-light">
            <div className="container">
              <h2 className="text-center fw-bold mb-5">Contact Us</h2>
              <div id="lead-gen-form">
                <LeadGenerationForm />
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default MotorcycleTowingPhoenix;