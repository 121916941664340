import React, { useState, useEffect } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  RadioGroup,
  Radio,
  FormLabel,
} from '@mui/material';

const LeadGenerationForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    location: '',
    vehicleCondition: '',
    serviceNeeded: [],
    fullName: '',
    email: '',
    alternatePhone: '',
    crossStreets: '',
    locationType: '',
    year: '',
    make: '',
    model: '',
    vehicleColor: '',
    licensePlate: '',
    specialInstructions: '',
    photos: [],
  });
  const [coordinates, setCoordinates] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Fetch user's location on mount
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
          setError('Unable to detect location. Please enter it manually.');
        },
        { enableHighAccuracy: true }
      );
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCheckboxChange = (e, key) => {
    const value = e.target.value;
    setFormData((prev) => {
      const updatedArray = prev[key].includes(value)
        ? prev[key].filter((item) => item !== value)
        : [...prev[key], value];
      return { ...prev, [key]: updatedArray };
    });
  };

  const handleDetectLocation = () => {
    if (coordinates) {
      setFormData((prev) => ({
        ...prev,
        location: `Lat: ${coordinates.lat}, Lng: ${coordinates.lng}`,
      }));
      setError(null);
    } else {
      setError('Unable to detect location. Please try again or enter it manually.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = ['phoneNumber', 'location', 'make', 'model', 'year'];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      setError('Please fill out all required fields.');
      return;
    }

    console.log('Form submitted:', { ...formData, coordinates });
    setSuccess(true);
    setError(null);
  };

  const validateStep = () => {
    if (step === 1 && !formData.phoneNumber) return false;
    if (step === 3 && (!formData.year || !formData.make || !formData.model)) return false;
    return true;
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Snackbar
        open={success}
        onClose={() => setSuccess(false)}
        autoHideDuration={6000}
        message="Form submitted successfully!"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />

      <Alert severity="warning" sx={{ mb: 3 }}>
        <AlertTitle>Emergency</AlertTitle>
        For immediate assistance, call 911 if this is a life-threatening emergency.
      </Alert>

      <Card>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            Request Roadside Assistance
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            {/* Step 1 */}
            {step === 1 && (
              <>
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  error={!formData.phoneNumber && error}
                  helperText={!formData.phoneNumber && error ? 'Phone number is required.' : ''}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TextField
                    label="Current Location"
                    name="location"
                    fullWidth
                    margin="normal"
                    value={formData.location}
                    onChange={handleInputChange}
                    placeholder={
                      coordinates
                        ? 'Click "Detect Location" or enter manually'
                        : 'Enter location manually'
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDetectLocation}
                  >
                    Detect Location
                  </Button>
                </Box>
              </>
            )}

            {/* Step 2 */}
            {step === 2 && (
              <>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  What type of service do you need?
                </Typography>
                {[
                  'Towing',
                  'Lockout',
                  'Flat Tire',
                  'Battery Jumpstarts',
                  'Windshield Chip Repairs',
                  'Transportation / Ride',
                  'Other',
                ].map((service) => (
                  <FormControlLabel
                    key={service}
                    control={
                      <Checkbox
                        name="serviceNeeded"
                        value={service}
                        checked={formData.serviceNeeded.includes(service)}
                        onChange={(e) => handleCheckboxChange(e, 'serviceNeeded')}
                      />
                    }
                    label={service}
                  />
                ))}

                <FormLabel sx={{ mt: 3 }}>Is the vehicle in running condition?</FormLabel>
                <RadioGroup
                  name="vehicleCondition"
                  value={formData.vehicleCondition}
                  onChange={handleInputChange}
                  sx={{ flexDirection: 'column', mt: 1 }}
                >
                  <FormControlLabel
                    value="Running"
                    control={<Radio />}
                    label="Yes - Running (Rolls, Brakes, Steers)"
                  />
                  <FormControlLabel
                    value="Non-Running"
                    control={<Radio />}
                    label="No - Non-Running Vehicle"
                  />
                </RadioGroup>
              </>
            )}

            {/* Step 3 */}
            {step === 3 && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Year"
                      name="year"
                      fullWidth
                      margin="normal"
                      type="number"
                      value={formData.year}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Make"
                      name="make"
                      fullWidth
                      margin="normal"
                      value={formData.make}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Model"
                      name="model"
                      fullWidth
                      margin="normal"
                      value={formData.model}
                      onChange={handleInputChange}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Vehicle Color"
                      name="vehicleColor"
                      fullWidth
                      margin="normal"
                      value={formData.vehicleColor}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {/* Navigation Buttons */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
              {step > 1 && (
                <Button variant="outlined" onClick={() => setStep(step - 1)}>
                  Back
                </Button>
              )}
              {step < 3 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (validateStep()) {
                      setStep(step + 1);
                    } else {
                      setError('Please complete all required fields.');
                    }
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button variant="contained" color="success" type="submit">
                  Submit
                </Button>
              )}
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LeadGenerationForm;