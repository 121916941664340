import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const Contact = () => {
  // Breadcrumbs for this page
  const breadcrumbs = [
    { label: "Home", href: "/" },
    { label: "Contact Us", href: "/contact", active: true },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className="contact-page">
        <Helmet>
          <title>Contact Us - Phoenix Towing Now</title>
          <meta
            name="description"
            content="Contact Phoenix Towing Now for 24/7 towing services. Prompt, reliable, and honest assistance whenever you need it. Get in touch today!"
          />
        </Helmet>

        {/* Hero Section */}
        <header className="hero-section text-center py-4 bg-light">
          <h1 className="fw-bold mb-3">Contact Phoenix Towing Now</h1>
          <p className="mb-4">
            We're here to assist you 24/7 with prompt, reliable, and honest towing and roadside assistance services. Reach out to us anytime for help!
          </p>
          <button
            onClick={() => window.callNow()}
            className="btn btn-danger btn-lg fw-bold mx-2"
          >
            Call Now: 602-483-1751
          </button>
        </header>

        {/* Page Content */}
        <div className="page-content container">
          <section className="about-us text-center mb-5">
            <h2 className="fw-bold mb-4">Committed to Exceptional Service</h2>
            <p>
              At Phoenix Towing Now, our goal is to provide top-notch service with
              integrity, transparency, and promptness. Our team understands how
              stressful roadside emergencies can be, and we are here to offer
              reliable assistance with a focus on customer satisfaction.
            </p>
            <p>
              Whether you're in need of towing, roadside help, or assistance with
              vehicle lockouts, you can count on us to handle your needs with care and
              professionalism.
            </p>
          </section>

          {/* Contact Form Section */}
          <section id="contact-form" className="py-5 bg-light">
            <div className="container">
              <h2 className="text-center fw-bold mb-4">Get in Touch</h2>
              <p className="text-center mb-4">
                Have questions or need immediate assistance? Fill out the form below, and
                our team will get back to you promptly. For urgent requests, call us
                directly at <strong>602-483-1751</strong>.
              </p>
              <div id="lead-gen-form">
                <LeadGenerationForm />
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;