import React from "react";
import { Helmet } from "react-helmet";
import LeadGenerationForm from "../components/LeadGenerationForm";
import Layout from "../components/Layout";

const PhoenixAutoAccidentTowing = () => {
    // Breadcrumbs specific to this page
    const breadcrumbs = [
      { label: "Home", href: "/" },
      { label: "Phoenix Auto Accident Towing", href: "/phoenix-auto-accident-towing", active: true },
    ];
  
    return (
      <Layout breadcrumbs={breadcrumbs}>
    <div className="phoenix-auto-accident-towing">
      <Helmet>
        <title>Auto Accident Towing in Phoenix AZ - 24 Hour Towing Service</title>
        <meta
          name="description"
          content="Stranded due to an auto accident in Phoenix AZ? Rely on our 24/7 towing service. We ensure safe, prompt & stress-free recovery for all vehicle types."
        />
      </Helmet>
      
      {/* Hero Section */}
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          Auto Accident Towing in Phoenix AZ -<br />
          <span>24 Hour Towing Service</span>
        </h1>
        <p className="mb-4">
          Stranded due to an auto accident? Access professional and stress-free towing services 24/7 in Phoenix.
        </p>
        <button onClick={() => window.callNow()} className="btn btn-danger btn-lg fw-bold mx-2">
          Call Now: 602-483-1751
        </button>
        <button onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })}
          className="btn btn-primary btn-lg">
          Request Service
        </button>
      </header>

      {/* Page Content */}
      <div className="page-content container">
        <section className="intro">
          <h2 className="mb-3">Welcome to Phoenix Auto Accident Towing</h2>
          <p>
            When accidents happen, our team is here to provide reliable, safe, and prompt towing services.
            Trust us to handle your vehicle with care and help you navigate the post-accident process.
          </p>
        </section>

        <section className="services">
          <h2 className="mb-3">Comprehensive Auto Accident Towing Solutions</h2>
          <p>We specialize in towing and recovery services tailored for accident scenarios:</p>
          <ul>
            <li><strong>Immediate Assistance:</strong> Rapid response for accident scenes.</li>
            <li><strong>Specialty Vehicle Care:</strong> Safe transport for luxury, electric, and oversized vehicles.</li>
            <li><strong>Damage-Free Towing:</strong> Advanced equipment for secure handling.</li>
          </ul>
        </section>

        <section className="what-to-do">
          <h2 className="mb-3">What To Do After an Auto Accident in Phoenix</h2>
          <ul>
            <li><strong>Ensure Safety:</strong> Move away from traffic if possible.</li>
            <li><strong>Call the Police:</strong> Report the accident and cooperate fully.</li>
            <li><strong>Seek Medical Attention:</strong> Even minor injuries should be checked.</li>
            <li><strong>Document the Scene:</strong> Take photos of vehicles, damage, and surroundings.</li>
          </ul>
          <p>
            For more information, check out this helpful{" "}
            <a href="https://www.jkphoenixpersonalinjuryattorney.com/what-to-do-after-the-accident/" target="_blank" rel="noopener noreferrer">
              guide on accident safety.
            </a>
          </p>
        </section>

        <section className="fleet">
          <h2 className="mb-3">Our Towing Fleet for Every Auto Scenario</h2>
          <p>
            Equipped with state-of-the-art tools, our fleet is designed to handle vehicles of all types and sizes, ensuring safe and efficient transport.
          </p>
          <ul>
            <li>Motorcycles</li>
            <li>Compact Cars</li>
            <li>Luxury and Electric Vehicles</li>
            <li>Heavy-Duty Trucks</li>
          </ul>
        </section>

        <section className="pricing">
          <h2 className="mb-3">Transparent Pricing and No Hidden Fees</h2>
          <p>
            We provide upfront quotes, so you’ll know exactly what to expect. Our team is happy to walk you through the invoice and answer any questions about costs.
          </p>
        </section>

        <section className="support">
          <h2 className="mb-3">24/7 Support and Customer Excellence</h2>
          <p>
            With rapid response times and a decade of trusted service, we’re committed to helping Phoenix drivers get back on the road quickly and safely.
          </p>
        </section>

        <section className="faq">
          <h2 className="mb-3">FAQs About Auto Accident Towing in Phoenix</h2>
          <h3>What immediate steps should I take after an accident?</h3>
          <p>
            Ensure your safety by moving to a secure location, call the police, and gather documentation such as photos and insurance details.
          </p>
          <h3>How quickly can a tow truck reach me in Phoenix?</h3>
          <p>
            Our team typically arrives within 20-30 minutes, depending on traffic and location.
          </p>
          <h3>What are my rights if my car is impounded after an accident?</h3>
          <p>
            You can request a hearing within 10 days to contest impoundment and may secure early release with proper documentation.
          </p>
          <h3>How do I handle towing fees with my insurance company?</h3>
          <p>
            Keep all receipts and provide them to your insurance provider as part of your claim. We coordinate directly with insurance companies to streamline this process.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Contact Us</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </div>
    </Layout>
  );
};

export default PhoenixAutoAccidentTowing;