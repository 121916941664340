import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const FlatbedTowingPhoenix = () => {
    // Breadcrumbs specific to this page
    const breadcrumbs = [
      { label: "Home", href: "/" },
      { label: "Flatbed Towing Phoenix", href: "/flatbed-towing-phoenix", active: true },
    ];
  
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>Reliable Flatbed Towing in Phoenix: 24/7 Service</title>
        <meta
          name="description"
          content="Experience reliable 24/7 flatbed towing in Phoenix. Emergency assistance, secure transport for all vehicles, and comprehensive support with upfront rates."
        />
      </Helmet>

      {/* Hero Section */}
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          Reliable Flatbed Towing in Phoenix -<br />
          <span>24/7 Service</span>
        </h1>
        <p className="mb-4">
          Experience safe, secure, and reliable flatbed towing for all vehicle types. Available 24/7 for emergencies and scheduled services.
        </p>
        <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mx-2"
        >
          Call Now: 602-483-1751
        </button>
        <button
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
          className="btn btn-primary btn-lg"
        >
          Request Service
        </button>
      </header>

      {/* Page Content */}
      <div className="page-content container">
        <section className="intro">
          <h2 className="mb-3">Always Ready for Your Towing Needs</h2>
          <p>
            Whether it’s a breakdown, accident, or scheduled transport, our flatbed towing service ensures your vehicle is transported safely and efficiently.
            With 24/7 availability, you can count on us to be there whenever you need us.
          </p>
        </section>

        <section className="services">
          <h2 className="mb-3">Flatbed Towing Services</h2>
          <p>Our specialized flatbed towing services are designed for the safe transport of any vehicle:</p>
          <ul>
            <li><strong>24/7 Emergency Assistance:</strong> Ready for immediate dispatch, day or night.</li>
            <li><strong>Specialized Towing Solutions:</strong> Perfect for luxury cars, motorcycles, and non-operational vehicles.</li>
            <li><strong>Safe and Secure Transportation:</strong> Protect your vehicle from potential damage during transport.</li>
          </ul>
        </section>

        <section className="roadside-support">
          <h2 className="mb-3">Towing and Comprehensive Roadside Support</h2>
          <p>
            In addition to flatbed towing, we offer a range of roadside services to ensure you’re back on the road quickly:
          </p>
          <ul>
            <li>Quick response for lockouts, jumpstarts, and tire changes.</li>
            <li>Windshield chip repair to prevent further damage.</li>
          </ul>
        </section>

        <section className="why-choose-us">
          <h2 className="mb-3">Why Choose Us for Flatbed Towing?</h2>
          <p>
            With over a decade of trusted service in Phoenix, we’re proud to provide honest, transparent, and efficient towing solutions:
          </p>
          <ul>
            <li><strong>A Decade of Experience:</strong> Trusted by Phoenix residents for reliable service.</li>
            <li><strong>No Hidden Fees:</strong> Upfront pricing ensures complete transparency.</li>
            <li><strong>Commitment to Excellence:</strong> Our team is dedicated to your satisfaction and safety.</li>
          </ul>
        </section>

        <section className="service-area">
          <h2 className="mb-3">Service Area Coverage</h2>
          <p>
            We proudly serve the entire Phoenix metro area, providing both local and interstate towing solutions:
          </p>
          <ul>
            <li>Reliable service across Maricopa County and surrounding areas.</li>
            <li>Interstate vehicle transport and local hauls tailored to your needs.</li>
          </ul>
        </section>

        <section className="testimonials">
          <h2 className="mb-3">Hear from Our Satisfied Customers</h2>
          <blockquote>
            <p>
              “Fast, reliable, and professional service. They transported my classic car safely and securely!” – Alex J.
            </p>
          </blockquote>
          <blockquote>
            <p>
              “The best towing company in Phoenix! Their flatbed service was exactly what I needed for my electric vehicle.” – Lisa T.
            </p>
          </blockquote>
        </section>

        <section className="faq">
          <h2 className="mb-3">FAQ: Expert Answers to Your Towing Queries</h2>
          <h3>What types of vehicles can you tow with a flatbed truck?</h3>
          <p>
            Our flatbed trucks are equipped to handle motorcycles, sedans, SUVs, luxury vehicles, electric cars, and heavy-duty trucks.
          </p>
          <h3>How do I get emergency towing services in Phoenix?</h3>
          <p>
            Simply call us at <strong>(602) 483-1751</strong>. Our team is available 24/7 for immediate dispatch to your location.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Request Service Now!</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default FlatbedTowingPhoenix;