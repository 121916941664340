import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Layout = ({ children, breadcrumbs = [] }) => {
  return (
    <>
    {/* Helmet for Meta Tags and Google Analytics */}
    <Helmet>
    {/* Google Analytics */}
    <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`}></script>
    <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.REACT_APP_GA_TRACKING_ID}');
        `}
    </script>
    <script type="application/ld+json">
        {JSON.stringify({
        "@context": "http://schema.org",
        "@type": "EmergencyService",
        "name": "Phoenix Towing Now",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "3409 N 7th Ave\nSte C109 Unit 100",
            "addressLocality": "Phoenix",
            "addressRegion": "AZ",
            "postalCode": "85013",
            "addressCountry": "US"
        },
        "telephone": "+1-602-483-1751",
        "url": "https://www.phoenixtowingnow.com",
        "openingHours": "Mo-Su 00:00-23:59"
        })}
    </script>
    </Helmet>
      {/* Emergency Banner */}
      <div className="bg-danger text-white text-center py-2">
        <strong>24/7 Emergency Service:</strong> Call{" "}
        <a href="tel:+1-602-483-1751" className="text-white fw-bold">
          602-483-1751
        </a>
      </div>
      
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container">
    <a className="navbar-brand" href="/">Phoenix Towing Now</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <a className="nav-link" href="/">Home</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="servicesDropdown" role="button" data-bs-toggle="dropdown">
            Services
          </a>
          <ul className="dropdown-menu">
            <li><h6 className="dropdown-header">Emergency Towing Services</h6></li>
            <li><a className="dropdown-item" href="/emergency-towing-phoenix">24/7 Emergency Towing Phoenix</a></li>
            <li><div className="dropdown-divider"></div></li>
            
            <li><h6 className="dropdown-header">Area Services</h6></li>
            <li><a className="dropdown-item" href="/towing-service-phoenix">Phoenix Towing</a></li>
            <li><a className="dropdown-item" href="/towing-service-glendale">Glendale Towing</a></li>
            <li><a className="dropdown-item" href="/towing-service-mesa">Mesa Towing</a></li>
            <li><a className="dropdown-item" href="/towing-service-scottsdale">Scottsdale Towing</a></li>
            <li><a className="dropdown-item" href="/towing-service-tempe">Tempe Towing</a></li>
            <li><div className="dropdown-divider"></div></li>
            <li><h6 className="dropdown-header">Specialized Towing</h6></li>
            <li><a className="dropdown-item" href="/flatbed-towing-phoenix">Flatbed Towing</a></li>
            <li><a className="dropdown-item" href="/motorcycle-towing-phoenix">Motorcycle Towing</a></li>
            <li><a className="dropdown-item" href="/phoenix-auto-accident-towing">Auto Accident Towing</a></li>
            <li><div className="dropdown-divider"></div></li>
            
            <li><h6 className="dropdown-header">Roadside Assistance</h6></li>
            <li><a className="dropdown-item" href="/roadside-assistance-phoenix">Roadside Assistance</a></li>
            <li><a className="dropdown-item" href="/flat-tire-assistance-phoenix">Flat Tire Assistance</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/contact">Contact Us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

      {/* Breadcrumbs */}
      {breadcrumbs.length > 0 && (
        <div className="breadcrumb-container bg-light py-2">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb container">
              {breadcrumbs.map((breadcrumb, index) => (
                <li
                  key={index}
                  className={`breadcrumb-item ${breadcrumb.active ? "active" : ""}`}
                  aria-current={breadcrumb.active ? "page" : undefined}
                >
                  {breadcrumb.active ? (
                    breadcrumb.label
                  ) : (
                    <a href={breadcrumb.href}>{breadcrumb.label}</a>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      )}

      {/* Page Content */}
      <main>{children}</main>

      {/* Footer */}
      <footer className="bg-dark text-white text-center py-4">
        <p>&copy; 2024 Phoenix Towing Now</p>
      </footer>
    </>
  );
};

export default Layout;