import React, { useState } from "react";

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqs = [
    {
      question: "What types of vehicles can you tow?",
      answer:
        "We tow a wide range of vehicles, including motorcycles, sedans, SUVs, trucks, RVs, and exotic or non-operational vehicles. Our fleet includes flatbed trucks and specialized equipment for safe transport.",
    },
    {
      question: "Is your towing service available 24/7?",
      answer:
        "Yes, we operate 24/7 to assist you anytime, whether it’s a late-night breakdown or an early-morning emergency.",
    },
    {
      question: "How quickly can you reach me?",
      answer:
        "Our average response time is 20-30 minutes, depending on your location and traffic conditions. We cover the entire Phoenix Metro Area for prompt service.",
    },
    {
      question: "Do you provide towing for long distances?",
      answer:
        "Yes, we offer local and interstate towing along major corridors such as the I-10, I-17, and US 60.",
    },
    {
      question: "What roadside assistance services do you offer?",
      answer:
        "Our roadside assistance includes flat tire changes, battery jumpstarts, vehicle lockout solutions, fuel delivery, and windshield chip repairs.",
    },
    {
      question: "How much does your towing service cost?",
      answer:
        "Pricing depends on factors such as the type of vehicle, distance, and service required. We provide upfront quotes with no hidden fees to ensure complete transparency.",
    },
    {
      question: "What areas do you serve?",
      answer:
        "We serve the entire Phoenix Metro Area, including Glendale, Mesa, Scottsdale, Tempe, and surrounding counties. Our service extends along major highways like the I-10, I-17, US 60, and Loops 101 and 202.",
    },
  ];

  return (
    <section className="faq-section py-5 bg-light">
      <div className="container faq-wrapper">
        <h2 className="text-center fw-bold mb-4">FAQ: Your Towing Questions Answered</h2>
        <div className="accordion" id="faqAccordion">
          {faqs.map((faq, index) => (
            <div key={index} className="accordion-item mb-3">
              <h3 className="accordion-header">
                <button
                  className={`accordion-button ${activeIndex === index ? "" : "collapsed"}`}
                  type="button"
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={activeIndex === index}
                  aria-controls={`faq${index}`}
                >
                  {faq.question}
                </button>
              </h3>
              <div
                id={`faq${index}`}
                className={`accordion-collapse collapse ${
                  activeIndex === index ? "show" : ""
                }`}
              >
                <div className="accordion-body">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordion;