import React from "react";
import LeadGenerationForm from "../components/LeadGenerationForm";
import FAQAccordion from "../components/FAQAccordion";

const Home = () => {
  return (
    <>
    {/* Header Section */}
<header className="hero-section text-center text-white py-5" style={{ 
    background: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/hero-bg.jpg') center/cover" 
}}>
  <div className="container">
    <h1 className="fw-bold mb-3">24/7 Towing Services in Phoenix</h1>
    <p className="lead mb-4">Fast. Reliable. Affordable.</p>
    <div className="d-flex justify-content-center gap-3 flex-wrap">
      <button 
        onClick={() => window.callNow()} 
        className="btn btn-danger btn-lg fw-bold">
        Call Now: 602-483-1751
      </button>
      <button 
        onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })} 
        className="btn btn-primary btn-lg">
        Request Service
      </button>
    </div>
  </div>
</header>
      {/* Contact Section */}
      <section id="contact" className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center fw-bold mb-5">Contact Us</h2>
          <div id="lead-gen-form">          <LeadGenerationForm /> {/* Render the form directly */}
          </div> {/* React LeadGenerationForm Mounts Here */}
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-5">
        <div className="container">
          <h2 className="text-center fw-bold mb-5">Our Services</h2>
          <div className="row g-4">
            <div className="col-md-4">
              <div className="card service-card border-0 shadow-sm text-center py-4">
                <div className="icon-wrapper text-danger">
                  <i className="bi bi-truck display-3"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Emergency Towing</h5>
                  <p className="card-text">
                    24/7 towing for all vehicle types. Quick response guaranteed.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card service-card border-0 shadow-sm text-center py-4">
                <div className="icon-wrapper text-danger">
                  <i className="bi bi-tools display-3"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Roadside Assistance</h5>
                  <p className="card-text">
                    Jump starts, tire changes, fuel delivery, and lockout services.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card service-card border-0 shadow-sm text-center py-4">
                <div className="icon-wrapper text-danger">
                  <i className="bi bi-shield-fill-check display-3"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title fw-bold">Accident Recovery</h5>
                  <p className="card-text">
                    Reliable accident recovery and vehicle transport services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-5 bg-light">
        <div className="container">
          <h2 className="text-center fw-bold mb-5">What Our Customers Say</h2>
          <div className="row g-4">
            <div className="col-md-4">
              <div className="card testimonial-card border-0 shadow-sm">
                <div className="card-body text-center">
                  <p>
                    "Fast and professional service when I was stranded on the freeway."
                  </p>
                  <p className="text-muted">- Jane D.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card testimonial-card border-0 shadow-sm">
                <div className="card-body text-center">
                  <p>
                    "Their roadside assistance saved me during a flat tire emergency!"
                  </p>
                  <p className="text-muted">- John S.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card testimonial-card border-0 shadow-sm">
                <div className="card-body text-center">
                  <p>
                    "Highly recommend for quick and reliable towing in Phoenix."
                  </p>
                  <p className="text-muted">- Emma W.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FAQAccordion />
    </>
  );
};

export default Home;