import React from "react";
import { Helmet } from "react-helmet";
import LeadGenerationForm from "../components/LeadGenerationForm";
import Layout from "../components/Layout"; // Ensure Layout is correctly imported

const FlatTireAssistancePhoenix = () => {
    // Breadcrumbs specific to this page
    const breadcrumbs = [
      { label: "Home", href: "/" },
      { label: "Flat Tire Assistance Phoenix", href: "/flat-tire-assistance-phoenix", active: true },
    ];
  
  return (
    <Layout breadcrumbs={breadcrumbs}>
    <div className="flat-tire-assistance-phoenix">
      <Helmet>
        <title>Flat Tire Roadside Assistance Phoenix AZ</title>
        <meta
          name="description"
          content="Stranded with a flat tire in Phoenix, AZ? Get fast, reliable roadside assistance 24/7. Click for immediate help or learn about our free yearly service plan."
        />
      </Helmet>

      {/* Hero Section */}
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          Flat Tire Roadside Assistance Phoenix AZ
        </h1>
        <p className="mb-4">
          Stranded with a flat tire? Get reliable, fast roadside assistance 24/7 in Phoenix. 
        </p>
        <button onClick={() => window.callNow()} className="btn btn-danger btn-lg fw-bold mx-2">
          Call Now: 602-483-1751
        </button>
        <button onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })}
          className="btn btn-primary btn-lg">
          Request Service
        </button>
      </header>

      {/* Page Content */}
      <div className="page-content container">
        <section className="intro">
          <h2 className="mb-3">Welcome to Phoenix's Rapid Response for Flat Tires</h2>
          <p>
            We’re here to provide professional, reliable flat tire solutions whenever you need us. 
            Whether it's day or night, we’re ready to help Phoenix drivers get back on the road safely.
          </p>
        </section>

        <section className="services">
          <h2 className="mb-3">Our Expert Flat Tire Solutions</h2>
          <p>We offer comprehensive tire assistance services, including:</p>
          <ul>
            <li><strong>Professional Tire Change:</strong> Quick and efficient tire replacement.</li>
            <li><strong>Tire Repair:</strong> Minor repairs to get your tire back in shape.</li>
            <li><strong>High-Quality Spare Tires:</strong> Replacement options available upon request.</li>
          </ul>
        </section>

        <section className="why-choose-us">
          <h2 className="mb-3">Why Choose Us for Your Roadside Needs?</h2>
          <p>
            Our flat tire services are designed to offer convenience, speed, and reliability.
          </p>
          <ul>
            <li><strong>24/7 Availability:</strong> We’re always ready to assist, day or night.</li>
            <li><strong>Fast Turnaround:</strong> Our team ensures minimal downtime for your journey.</li>
            <li><strong>Comprehensive Support:</strong> From flat tires to lockouts, we’ve got you covered.</li>
          </ul>
        </section>

        <section className="coverage">
          <h2 className="mb-3">Coverage Area - We've Got Phoenix Covered</h2>
          <p>
            We provide services throughout the Phoenix metro area, including major highways and surrounding communities.
          </p>
        </section>

        <section className="faq">
          <h2 className="mb-3">Frequently Asked Questions</h2>
          <h3>What exactly does your flat tire service include?</h3>
          <p>
            We provide tire changes, repairs, and spare tires to help you get back on the road quickly and safely.
          </p>
          <h3>How quickly can you get to my location in Phoenix?</h3>
          <p>
            Our team typically arrives within 20-30 minutes, depending on traffic and location.
          </p>
          <h3>Are you equipped to service all types of vehicles?</h3>
          <p>
            Yes, we handle all vehicle types, including sedans, SUVs, trucks, and more.
          </p>
          <h3>What payment methods do you accept for roadside assistance?</h3>
          <p>
            We accept cash, credit/debit cards, and most major digital payment options.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Contact Us</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </div>
    </Layout>
  );
};

export default FlatTireAssistancePhoenix;