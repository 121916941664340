import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import LeadGenerationForm from "../components/LeadGenerationForm";

const TowingServicePhoenix = () => {
  const breadcrumbs = [
    { label: "Home", href: "/" },
    { label: "Phoenix Towing", href: "/towing-service-phoenix", active: true },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Helmet>
        <title>Phoenix's Best Towing Service - 24/7 Towing Across the Metro Area</title>
        <meta
          name="description"
          content="Need reliable towing in Phoenix, AZ? Serving the I-10, I-17, and the entire metro area, we provide fast and professional 24/7 towing services. Call now!"
        />
      </Helmet>

      {/* Hero Section */}
      <header className="hero-section text-center py-4 bg-light">
        <h1 className="fw-bold mb-3">
          Phoenix's Best Towing Service:<br />
          <span>24/7 Towing Across the Metro Area</span>
        </h1>
        <p className="mb-4">
          Need towing in Phoenix? Rely on us for fast, professional, and dependable service across the entire metro area.
        </p>
        <p className="mb-4">
          Available 24/7, we’re ready to assist with your towing and roadside needs—anytime, anywhere in Phoenix.
        </p>
        <button
          onClick={() => window.callNow()}
          className="btn btn-danger btn-lg fw-bold mx-2"
        >
          Call Now: 602-483-1751
        </button>
        <button
          onClick={() =>
            document.getElementById("contact").scrollIntoView({ behavior: "smooth" })
          }
          className="btn btn-primary btn-lg"
        >
          Request Service
        </button>
      </header>

      {/* Page Content */}
      <div className="page-content container">
        <section className="intro">
          <h2 className="mb-3">Welcome to Phoenix’s Most Reliable Towing Service</h2>
          <p>
            As Phoenix's trusted towing company, we provide fast, dependable, and professional services throughout the metro area. 
            Whether it’s a vehicle breakdown or a roadside emergency, you can count on us 24/7.
          </p>
        </section>

        <section className="services">
          <h3 className="mb-3">Comprehensive Towing and Roadside Assistance in Phoenix</h3>
          <p>
            Our expert team is equipped to handle all your towing needs, from light-duty vehicles to heavy-duty trucks. We pride ourselves 
            on offering efficient, safe, and tailored solutions to keep you moving.
          </p>
        </section>

        <section className="geographic-coverage">
          <h2 className="mb-3">Comprehensive Coverage Across the Phoenix Metro Area</h2>
          <ul>
            <li>Interstate 10 corridor</li>
            <li>Interstate 17 corridor</li>
            <li>US 60 corridor</li>
            <li>Loop 101 coverage</li>
            <li>Loop 202 coverage</li>
            <li>Downtown Phoenix</li>
            <li>Busy commercial districts</li>
            <li>High-traffic business areas</li>
          </ul>
        </section>

        <section className="weather-related-services">
          <h2 className="mb-3">Weather-Related Services for Phoenix Drivers</h2>
          <h3>Heat-Related Assistance</h3>
          <ul>
            <li>Vehicle overheating response</li>
            <li>Battery failure services</li>
            <li>Cooling system breakdowns</li>
            <li>Air conditioning failures</li>
          </ul>
          <h3>Seasonal Support</h3>
          <ul>
            <li>Monsoon season emergency response</li>
            <li>Flash flood assistance</li>
            <li>Extreme heat breakdown support</li>
          </ul>
        </section>

        <section className="why-choose-us">
          <h2 className="mb-3">Why Choose Our Phoenix Towing Service?</h2>
          <p>
            With years of experience serving Phoenix, we are known for our reliability, fast response times, and professional service. 
            We aim to provide you with peace of mind during every roadside situation.
          </p>
        </section>

        <section className="quick-contact">
          <h2 className="mb-3">Get Help Anytime, Anywhere in Phoenix</h2>
          <p>
            Contact us at <strong>(602) 483-1751</strong> for immediate assistance. Our team is ready to respond quickly and efficiently to any roadside emergency.
          </p>
        </section>

        <section className="faq">
          <h2 className="mb-3">FAQs About Our Phoenix Towing Services</h2>
          <h3>What areas in Phoenix do you cover?</h3>
          <p>
            We cover the entire Phoenix Metro Area, including major corridors such as the I-10, I-17, US 60, Loop 101, and Loop 202.
          </p>
          <h3>Do you offer heat-related breakdown services?</h3>
          <p>
            Yes, we specialize in handling vehicle overheating, battery failures, and other heat-related issues common in Phoenix’s extreme weather.
          </p>
          <h3>How quickly can you get to me?</h3>
          <p>
            Our average response time is 20-30 minutes, depending on traffic and location.
          </p>
        </section>

        {/* Contact Form */}
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center fw-bold mb-5">Contact Us</h2>
            <div id="lead-gen-form">
              <LeadGenerationForm />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TowingServicePhoenix;